var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("confirmation-values-container", {
        attrs: { items: _vm.valueItems, "is-swap": "" },
      }),
      _vm.summaryItems.length == 2
        ? _c("confirmation-summary-block", {
            attrs: { items: _vm.summaryItems },
            scopedSlots: _vm._u(
              [
                {
                  key: "rightColItem0",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(" 1 "),
                        _c("span", { staticClass: "greyPrimary--text" }, [
                          _vm._v(_vm._s(_vm.fromType)),
                        ]),
                        _vm._v(" = " + _vm._s(_vm.formattedRate) + " "),
                        _c("span", { staticClass: "greyPrimary--text" }, [
                          _vm._v(_vm._s(_vm.toType)),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "rightColItem1",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(" " + _vm._s(_vm.convertedFees.value) + " "),
                        _c("span", { staticClass: "greyPrimary--text" }, [
                          _vm._v(_vm._s(_vm.network.type.currencyName)),
                        ]),
                        _vm._v(" ~" + _vm._s(_vm.txFeeUSD) + " "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1251509770
            ),
          })
        : _vm._e(),
      _vm.summaryItems.length == 3
        ? _c("confirmation-summary-block", {
            attrs: { items: _vm.summaryItems },
            scopedSlots: _vm._u(
              [
                {
                  key: "rightColItem0",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "mew-body d-flex justify-end" },
                        [
                          _c("mew-tooltip", {
                            attrs: { "hide-icon": "", text: _vm.toAddress },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activatorSlot",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("mew-blockie", {
                                            attrs: {
                                              address: _vm.toAddress,
                                              width: "20px",
                                              height: "20px",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "searchText--text ml-2",
                                                },
                                                "span",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(_vm.toAddressShortened)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3780395710
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "rightColItem1",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(" 1 "),
                        _c("span", { staticClass: "searchText--text" }, [
                          _vm._v(_vm._s(_vm.fromType)),
                        ]),
                        _vm._v(" = " + _vm._s(_vm.formattedRate) + " "),
                        _c("span", { staticClass: "searchText--text" }, [
                          _vm._v(_vm._s(_vm.toType)),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "rightColItem2",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(" " + _vm._s(_vm.convertedFees.value) + " "),
                        _c("span", { staticClass: "searchText--text" }, [
                          _vm._v(_vm._s(_vm.convertedFees.unit)),
                        ]),
                        _vm._v(" ~" + _vm._s(_vm.txFeeUSD) + " "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2108924077
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }